<template>
    <div class="aipage">
        <div class="banner-img background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/banner.png')})`}">
            <div class="banner">
                <div class="banner-title background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/caseTitle.png')})`}"></div>
                <div class="banner-smallTitle">质检一直是工业生产制造型企业的重要一环，企业对此投入了大量的资源，随着人工智能技术的发展，越来越多的相关技术投入到视觉检测中。</div>
            </div>
        </div>
        <div class="aipage_wrap">
            <div class="aipage_content">
                <p class="titleBlue">客户案例</p>
                <div class="flex-between">
                    <div style="width: calc(100% - 5.5rem);">
                        <p :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/titlebg.png')})`,'background-size':'cover','font-size':'0.16rem', 'width':'3.6rem', 'height':'0.42rem','line-height':'0.42rem','color':'#fff','font-weight':'600','text-indent': '0.12rem','margin-bottom':'0.2rem','font-family':'黑体'}">AI视觉算法检测登山扣瑕疵</p>
                        <p class="titleBlueSmall">背景介绍</p>
                        <div class="case-content" style="margin-bottom: 0.4rem;">
                            登山扣为五金制品，在工厂加工的时候会有部分工件在折弯处断裂。工厂打算用一台视觉检测设备来检测工件是否存在裂痕或和断裂。工件有黑色和白色两种。
                        </div>
                        <p class="titleBlueSmall">传统检测模式痛点</p>
                        <div class="case-content">
                            登山扣、安全绳、安全带之类的安全工具在使用过程中都需要承载一定的力，质量不合格将会直接威胁到人身安全，因此，这类产品的企业对其产品质量具有严格要求。某生产厂家一直采用传统的人工方法对登山扣进行质量缺陷检测，由于人工长时间视觉检查，眼睛易疲劳，导致产品检测效率低且精度不高。
                        </div>
                    </div>
                    <div class="background" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/MountaineeringBuckle.png')})`, 'width':'4.7rem', 'height':'3.2rem', 'background-size':'cover'}"></div>
                </div>
            </div>
        </div>
        <div class="aipage_wrap" style="padding-bottom: 0rem;">
            <div class="aipage_content">
                <p class="titleBlueSmall">AI检测优势</p>
                <div class="flex-between">
                    <div class="case-box flex-center" v-for="item in aiAdvantages" :key="item.index">
                        <img :src="item.icon" alt="" style="width:0.38rem">
                        <div style="margin: 0.18rem;font-weight: 600;font-size:0.16rem;">{{ item.title }}</div>
                        <div style="font-size:0.12rem;text-align: left;color: #a8a8a8;">{{ item.content }}</div>
                    </div>
                </div>
                <div class="flex-between" style="margin:0.4rem 0">
                   <img :src="require('@/assets/images/case/case1.png')" alt="" style="margin-right: 0.5rem;">
                   <div class="case_content">为了帮助厂家解决以上需求，中科逆熵通过AI视觉算法，基于南京智算中心强大的运算能力，在数千张图上检测出登山扣的裂痕或断裂，并对其位置进行标记处理，帮助厂家有效提高生产效率以及检验精度，降低人工成本，
                        提升产品市场核心竞争力，在售后阶段极大地降低了客户投诉、返厂等问题。此AI视觉算法主要通过计算机来模拟人的视觉功能，并且不仅仅是人眼的简单延伸，更重要的是具有人脑的一部分功能从——客观事物的图像中提取信息，
                        进行处理并加以理解，最终用于实际检测、测量和控制。
                    </div>
                </div>
            </div>
        </div>
        <div class="aipage_wrap">
            <div class="aipage_content" :style="{ 'backgroundImage' : `url(${require('@/assets/images/case/casebg4.png')})`, 'width':'12rem', 'height':'3.8rem','background-size':'cover'}" style="margin-bottom:0.3rem;letter-spacing: 0.01rem;color: #fff;text-indent: 0.28rem;display: flex;justify-content: center;flex-direction: column;">
                <span style='display:inline-block;width:5rem;line-height:0.24rem;margin-left:0.4rem'>目前我国工业制造产业已进入工业4.0时代，开启了自动化生产，中科逆熵的AI视觉算法，基于南京智算中心强大的算力规模，为企业生产制造提供高效便捷的AI检测算法，广泛应用到自动上下料、组装、分类分拣、铸造、喷涂与焊接引导等多种不同工业场景，
                极大地提高了生产效果、节约了人力成本。中科逆熵在各大领域均拥有完善的工业视觉应用案例，帮助传统制造实现产业升级。</span>
            </div>
        </div>
    </div>
  </template>
  <script>
  /* eslint-disable */
  export default {
    data() {
      return {
        aiAdvantages:[
            { title:'提升检测效率', icon:require('@/assets/images/case/aiIcon1.png'), content:'由于传统的自动化视觉检测系统过度依赖于缺陷库，为了确保信息被准确的检测到，人工智能视觉检测通过深度学习不需要通过复杂的算法，可在短间内就训练完成。' },
            { title:'降低劳动力成本', icon:require('@/assets/images/case/aiIcon2.png'), content:'人工智能视觉检测方案比传统人工检测的准确度更高。人工检测首先要通过培训，大约每次只能保持15-20分钟的高度集中，并且还存在员工流动性的问题。正因为这些因素，通过使用人工智能视觉检测技术来检测比手工劳动更划算。' },
            { title:'产品改进和质量控制', icon:require('@/assets/images/case/aiIcon3.png'), content:'生产厂家可以通过视觉检测算法来检测产品的质量。在整个过程中可以对工业部件进行数据跟踪，并实施改进。此外，它还可以防止未来的故障，从而节省了时间和额外的生产成本。' },
        ]
      }
    },
   
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.plateFormInfo = this.plateFormFunction[0]
    },
    methods: {
        
    },
  }
    </script>
<style lang="scss" scoped>
.banner {
    left: 20%;
    top:1.4rem;
    &-title{
        width: 4.7rem;
        height: 1.4rem;
        background-size: contain;
    }
    &-smallTitle{
        color: #fff;
        width: 6.8rem;
        letter-spacing: 2px;
        line-height: 0.24rem;
        font-size: 0.14rem;
    }
}
.background{
    background-position: center;
    background-repeat: no-repeat;
}
.banner-img{
    width: 100%;
    height: 2.8rem;
    position: relative;
    background-size: cover;
}
.titleBlue{
    font-size: 0.3rem;
    color: #0061E3;
    font-weight: 600;
    letter-spacing: 0.08rem;
    text-align: center;
    height: 0.6rem;
    margin: 0.2rem 0;
    font-family: '黑体';
}
.titleBlueSmall{
    font-size: 0.16rem;
    height: 0.3rem;
    margin: 0 0.12rem;
    color: #0061E3;
    font-weight: 600;
    font-family: '黑体';
}
.whiteBlue{
    width: 100%;
    font-size: 0.28rem;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.08rem;
    text-align: center;
    height: 0.6rem;
}
.case-content{
    font-size: 0.14rem;
    color: #333;
    text-indent: 0.28rem;
    line-height: 0.28rem;
}
.case-box{
    width: 3.4rem;
    height: 1.6rem;
    padding: 0.4rem 0.2rem;
    border: 1px solid #cfd3da;
    flex-direction: column;
    text-align: center;
    img{
        margin: 0 auto;
    }
}
.case_content{
    text-indent: 0.28rem;
    font-size: 0.14rem;
    letter-spacing: 0.01rem;
    line-height: 0.24rem;
    color: #333;
}
</style>
<style lang="scss">
.aipage{
    .el-button--small{
        padding:0.1rem 0.4rem;
        border-color: #0061e3;
        color: #0061e3;
        font-weight: 600;
        border-radius: 0;
    }
    .el-button--small:hover{
        background-color: #0061e3;
        color: #fff;
    }
}
.aipage_wrap{
    width: calc(100% - 0.48rem);
    display: flex;
    justify-content: center;
    padding: 0.24rem;
    background-color: #f5f5f5;
}
.aipage_content{
    width: 12rem;
}
.flex-center{
    display: flex;
    justify-content: center;
}
.flex-wrap{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.flex-between{
    display: flex;
    justify-content: space-between;
}
.dot-w{
    display: inline-block;
    width: 0.08rem;
    height: 0.08rem;
    background-color: #fff;
    border-radius: 50%;
}
.el-divider--horizontal{
    margin: 0.16rem 0;
}

</style>
 